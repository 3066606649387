html,
body,
#__next {
  height: 100%;
}

[data-reach-tab-panel]:focus,
[data-reach-tab]:focus {
  outline: 0;
}

/* reset the unwanted resets (in order to not modify the original reset file) */

ol,
ul {
  padding-top: 24px;
  padding-left: 1rem;
  list-style: initial;
}

body {
  line-height: 1;
  font-family: "Inter";
  -webkit-font-smoothing: antialiased;
}

body.mobile-menu-open {
  overflow: hidden;
}

a {
  color: #1f82c0;
}

input:focus {
  outline: 0;
}

/* to remove input type="number" arrow  */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@keyframes increase {
  from {
    left: -5%;
    width: 5%;
  }
  to {
    left: 130%;
    width: 100%;
  }
}
@keyframes decrease {
  from {
    left: -80%;
    width: 80%;
  }
  to {
    left: 110%;
    width: 10%;
  }
}

@font-face {
  font-family: Bagoss;
  src: url(/flow/fonts/BagossCondensed-Light.woff);
}

/* @keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
} */

@keyframes shineSkeleton {
  to {
    background-position-x: -400%;
  }
}

.Toastify__close-button {
  margin-top: 16px;
}
